<template>
  <VContent>
    <template slot="right">
      <h6>
        خطابات الضمان >
        <span class="text-muted">{{ title }}</span>
      </h6>
    </template>
    <template slot="left">
      <b-button size="sm" variant="success" class="ml-3" @click="update()"
        >تحديث</b-button
      >
    </template>
    <template slot="content">
      <VTextEditor
        v-for="(item, index) in information"
        :label="item.text"
        :key="index"
        class="mb-5"
        v-model="form[item.key]"
      ></VTextEditor>
    </template>
  </VContent>
</template>

<script>
import { clearObject, successAlert, errorAlert } from "@/utils/helper";

export default {
  data() {
    return {
      id: null,
      title: null,
      information: [
        { key: "about", text: "التعريف العام" },
        { key: "terms_and_conditions", text: "الأحكام والشروط" },
        { key: "procedures", text: "الأجراءات" },
        { key: "legal_framework", text: "الأطار القانوني" },
      ],
    };
  },
  computed: {
    form: {
      get() {
        return this.$store.state.banks.guarantee.form;
      },
      set(value) {
        this.$store.commit("banks/guarantee/SET_GUARANTEE", value);
      },
    },
  },
  watch: {
    $route() {
      this.get();
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.id = this.$route.meta.id;
      this.title = this.$route.name;
      this.$store.dispatch("banks/guarantee/get", this.id);
    },
    update() {
      this.$store
        .dispatch("banks/guarantee/update", this.id)
        .then((response) => {
          successAlert();
          this.$store.commit("banks/guarantee/SET_GUARANTEE", response.payload);
        })
        .catch((error) =>
          errorAlert(
            error.response ? error.response.data.message : "حدثت مشكله"
          )
        );
    },
    clearForm() {
      this.form = clearObject(this.form);
      this.$emit("close");
    },
  },
};
</script>
<style></style>
